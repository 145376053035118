const product = [
  {
    path: '/products',
    name: 'products.list',
    component: () => import('@/views/product/ProductList.vue'),
  },
  {
    path: '/products/bill-of-products/:uuid',
    name: 'products.bill-of-products.product-uuid',
    component: () => import('@/views/product/ProductBillOfMaterials.vue'),
  },
]

export default product
